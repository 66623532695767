<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  created() {
    // if (this.$store.getters["auth/getToken"] == "") {
    //   return null;
    // } else {
    //   this.$store.commit("odoo/fetchAllEmployees");
    // }

    if (this.$store.getters["auth/getToken"] == "") {
      return null;
    } else {
      this.$store.commit("auth/setAuth", true);
    }
  },
};
</script>
